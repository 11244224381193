/* ==========================================================================
   Plum skin
   ========================================================================== */

/* Colors */
$background-color: #521477 !default;
$text-color: #fffd86 !default;
$primary-color: #c327ab !default;
$border-color: mix(#fff, $background-color, 20%) !default;
$code-background-color: mix(#000, $background-color, 15%) !default;
$code-background-color-dark: mix(#000, $background-color, 20%) !default;
$form-background-color: mix(#000, $background-color, 15%) !default;
$footer-background-color: mix(#000, $background-color, 25%) !default;
$link-color: $primary-color !default;
$link-color-hover: mix(#fff, $link-color, 25%) !default;
$link-color-visited: mix(#000, $link-color, 25%) !default;
$masthead-link-color: $text-color !default;
$masthead-link-color-hover: mix(#000, $text-color, 20%) !default;
$navicon-link-color-hover: mix(#000, $background-color, 30%) !default;

/* plum syntax highlighting (base16) */
$base00: #ffffff !default;
$base01: #e0e0e0 !default;
$base02: #d0d0d0 !default;
$base03: #b0b0b0 !default;
$base04: #000000 !default;
$base05: #101010 !default;
$base06: #151515 !default;
$base07: #202020 !default;
$base08: #ff0086 !default;
$base09: #fd8900 !default;
$base0a: #aba800 !default;
$base0b: #00c918 !default;
$base0c: #1faaaa !default;
$base0d: #3777e6 !default;
$base0e: #ad00a1 !default;
$base0f: #cc6633 !default;

.author__urls.social-icons .svg-inline--fa,
.page__footer-follow .social-icons .svg-inline--fa {
  color: inherit;
}

.page__content {
  a,
  a:visited {
    color: inherit;
  }
}

/* next/previous buttons */
.pagination--pager {
  color: $text-color;
  background-color: $primary-color;
  border-color: transparent;

  &:visited {
    color: $text-color;
  }
}

.ais-search-box .ais-search-box--input {
  background-color: $form-background-color;
}